import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  ClipboardList, 
  Users, 
  FileText, 
  Cloud, 
  ArrowRight, 
  CheckCircle, 
  Smartphone, 
  Globe, 
  Calendar,
  Package,
  Map,
  BarChart,
  Settings,
  Mail
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import ContactForm from '../components/ContactForm';
import Loader from '../components/Loader';

const features = [
  {
    icon: Users,
    title: 'Gestion des clients',
    description: 'Base de données clients complète avec historique et informations détaillées'
  },
  {
    icon: Calendar,
    title: 'Agenda interactif',
    description: 'Planification des rendez-vous avec système de drag & drop et rappels'
  },
  {
    icon: FileText,
    title: 'Devis et factures',
    description: 'Création et gestion professionnelle des devis et factures avec modèles personnalisables'
  },
  {
    icon: Map,
    title: 'Géolocalisation',
    description: 'Optimisation des déplacements avec calcul d\'itinéraires et temps de trajet'
  },
  {
    icon: Package,
    title: 'Gestion des produits',
    description: 'Catalogue produits avec suivi des stocks et calcul automatique des marges'
  },
  {
    icon: ClipboardList,
    title: 'Gestion des tâches',
    description: 'Organisation et suivi des tâches par priorité et échéance'
  },
  {
    icon: BarChart,
    title: 'Tableaux de bord',
    description: 'Analyses et statistiques détaillées de votre activité'
  },
  {
    icon: Cloud,
    title: 'Sauvegarde cloud',
    description: 'Données synchronisées et accessibles sur tous vos appareils'
  }
];

const benefits = [
  "Gain de temps sur les tâches administratives",
  "Organisation optimisée des interventions",
  "Suivi précis de la rentabilité",
  "Meilleure gestion de la relation client",
  "Réduction des erreurs de facturation",
  "Accès aux données même hors connexion",
  "Interface simple et intuitive",
  "Support client réactif"
];

const LandingPage = () => {
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero Section */}
      <section className="relative min-h-[80vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600 via-blue-700 to-indigo-800 animate-gradient-xy"></div>
        <div className="absolute inset-0 opacity-30 bg-[url('https://images.unsplash.com/photo-1531973576160-7125cd663d86')] bg-cover bg-center"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-white">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-3xl mx-auto"
          >
            <motion.h1 
              className="text-5xl md:text-7xl font-bold mb-6 leading-tight"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Simplifiez la gestion de votre entreprise
            </motion.h1>
            <motion.p 
              className="text-xl md:text-2xl mb-12 text-blue-100"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              Simplifiez la gestion de votre entreprise avec notre application tout-en-un
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              {isAuthenticated ? (
                <Link
                  to="/dashboard"
                  className="group inline-flex items-center bg-white text-blue-600 px-8 py-4 rounded-full text-lg font-semibold hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  Accéder au tableau de bord
                  <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                </Link>
              ) : (
                <Link
                  to="/register"
                  className="group inline-flex items-center bg-white text-blue-600 px-8 py-4 rounded-full text-lg font-semibold hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  Essai gratuit de 30 jours
                  <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                </Link>
              )}
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Toutes les fonctionnalités dont vous avez besoin
            </h2>
            <p className="text-xl text-gray-600">
              Une solution complète pour gérer votre activité efficacement
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ y: -5 }}
                className="bg-white p-8 rounded-xl shadow-sm hover:shadow-xl transition-all duration-300"
              >
                <feature.icon className="h-12 w-12 text-blue-600 mb-6" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Les avantages de vite-gestion
            </h2>
            <p className="text-xl text-gray-600">
              Optimisez votre entreprise dès aujourd'hui
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="flex items-center space-x-4 bg-white p-6 rounded-xl shadow-sm"
              >
                <CheckCircle className="h-8 w-8 text-blue-600 flex-shrink-0" />
                <span className="text-lg text-gray-800">{benefit}</span>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Une offre simple et transparente
            </h2>
            <p className="text-xl text-gray-600">
              Tous les outils dont vous avez besoin dans un seul abonnement
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="max-w-lg mx-auto bg-white rounded-2xl shadow-xl overflow-hidden border-2 border-blue-500"
          >
            <div className="p-8">
              <div className="flex items-center justify-between mb-8">
                <div>
                  <h3 className="text-2xl font-bold text-gray-900">Pack Complet</h3>
                  <div className="mt-4 flex items-baseline">
                    <span className="text-4xl font-extrabold text-gray-900">29,90€</span>
                    <span className="ml-1 text-xl font-semibold text-gray-500">/mois</span>
                  </div>
                </div>
                <Globe className="h-12 w-12 text-blue-600" />
              </div>
              <ul className="space-y-4">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <CheckCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0" />
                    <span className="text-gray-600">{feature.title}</span>
                  </li>
                ))}
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Applications mobile iOS/Android</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Support client prioritaire</span>
                </li>
                <li className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">Mises à jour gratuites</span>
                </li>
              </ul>
              <div className="mt-8">
                <Link
                  to="/register"
                  className="block w-full text-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
                >
                  Commencer l'essai gratuit
                </Link>
                <p className="text-center text-sm text-gray-500 mt-2">
                  Sans engagement - Annulation facile
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20 bg-white relative overflow-hidden">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="bg-gradient-to-br from-blue-600 to-indigo-700 rounded-2xl shadow-xl p-8 text-white relative overflow-hidden"
          >
            <div className="absolute inset-0 bg-blue-600 opacity-50">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-90"></div>
            </div>
            <div className="relative">
              <h2 className="text-3xl font-bold text-center mb-8">Une question ?</h2>
              <ContactForm />
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;