import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Home, 
  Users, 
  FileText, 
  Settings, 
  Menu, 
  X, 
  FileCheck, 
  Calendar, 
  Clock, 
  Package,
  ChevronDown,
  ChevronRight
} from 'lucide-react';

const navigation = [
  { 
    name: 'Accueil', 
    href: '/dashboard', 
    icon: Home,
    color: 'text-blue-500'
  },
  {
    name: 'Clients',
    icon: Users,
    color: 'text-emerald-500',
    submenu: [
      { name: 'Liste des clients', href: '/dashboard/clients' },
      { name: 'Rendez-vous', href: '/dashboard/appointments' },
      { name: 'Agenda', href: '/dashboard/calendar' },
    ]
  },
  {
    name: 'Documents',
    icon: FileText,
    color: 'text-amber-500',
    submenu: [
      { name: 'Devis', href: '/dashboard/quotes' },
      { name: 'Factures', href: '/dashboard/invoices' },
    ]
  },
  {
    name: 'Gestion',
    icon: Package,
    color: 'text-teal-500',
    submenu: [
      { name: 'Produits', href: '/dashboard/products' },
      { name: 'Tâches', href: '/dashboard/tasks' },
    ]
  },
  { 
    name: 'Paramètres', 
    href: '/dashboard/settings', 
    icon: Settings,
    color: 'text-gray-500'
  },
];

const MobileNav = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const toggleExpanded = (name: string) => {
    setExpandedItems(prev => 
      prev.includes(name) 
        ? prev.filter(item => item !== name)
        : [...prev, name]
    );
  };

  return (
    <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40" onClick={() => setIsOpen(false)} />
      <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-lg z-50 p-4">
        <div className="flex justify-end mb-4">
          <button onClick={() => setIsOpen(false)} className="p-2 rounded-full hover:bg-gray-100">
            <X className="h-6 w-6" />
          </button>
        </div>
        <nav className="space-y-2">
          {navigation.map((item) => (
            <div key={item.name}>
              {item.submenu ? (
                <>
                  <button
                    onClick={() => toggleExpanded(item.name)}
                    className={`flex items-center justify-between w-full px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${item.color} hover:bg-gray-50`}
                  >
                    <div className="flex items-center">
                      <item.icon className="h-5 w-5 mr-3" />
                      {item.name}
                    </div>
                    <ChevronDown className={`h-4 w-4 transition-transform ${
                      expandedItems.includes(item.name) ? 'rotate-180' : ''
                    }`} />
                  </button>
                  <AnimatePresence>
                    {expandedItems.includes(item.name) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className="ml-4 pl-4 border-l border-gray-200 space-y-2 overflow-hidden"
                      >
                        {item.submenu.map((subItem) => (
                          <NavLink
                            key={subItem.href}
                            to={subItem.href}
                            className={({ isActive }) =>
                              `flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${
                                isActive ? `${item.color} bg-gray-50` : 'text-gray-600 hover:bg-gray-50'
                              }`
                            }
                            onClick={() => setIsOpen(false)}
                          >
                            <ChevronRight className="h-4 w-4 mr-2" />
                            {subItem.name}
                          </NavLink>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </>
              ) : (
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                      isActive ? `${item.color} bg-gray-50` : 'text-gray-600 hover:bg-gray-50'
                    }`
                  }
                  onClick={() => setIsOpen(false)}
                >
                  <item.icon className="h-5 w-5 mr-3" />
                  {item.name}
                </NavLink>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

const DashboardNav: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  return (
    <nav className="sticky top-0 bg-white shadow-sm z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between lg:justify-start items-center h-16">
          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:space-x-1">
            {navigation.map((item) => (
              <div
                key={item.name}
                className="relative"
                onMouseEnter={() => setHoveredItem(item.name)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {item.submenu ? (
                  <div>
                    <button className={`flex items-center px-3 py-2 text-sm font-medium transition-all duration-200 rounded-lg hover:bg-gray-50 ${item.color}`}>
                      <item.icon className="h-5 w-5 mr-2" />
                      <span>{item.name}</span>
                      <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${
                        hoveredItem === item.name ? 'rotate-180' : ''
                      }`} />
                    </button>
                    <AnimatePresence>
                      {hoveredItem === item.name && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute left-0 mt-1 w-48 bg-white rounded-lg shadow-lg py-2 z-50"
                        >
                          {item.submenu.map((subItem) => (
                            <NavLink
                              key={subItem.href}
                              to={subItem.href}
                              className={({ isActive }) =>
                                `flex items-center px-4 py-2 text-sm font-medium transition-colors ${
                                  isActive ? item.color : 'text-gray-600 hover:text-gray-900'
                                } hover:bg-gray-50`
                              }
                            >
                              <ChevronRight className="h-4 w-4 mr-2" />
                              {subItem.name}
                            </NavLink>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      `relative flex items-center px-3 py-2 text-sm font-medium transition-all duration-200 rounded-lg hover:bg-gray-50 ${
                        isActive ? item.color : 'text-gray-600'
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <div className="flex items-center">
                          <item.icon className="h-5 w-5 mr-2" />
                          {item.name}
                        </div>
                        {isActive && (
                          <motion.div
                            layoutId="nav-indicator"
                            className="absolute bottom-0 left-0 right-0 h-0.5 bg-current"
                            transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                          />
                        )}
                      </>
                    )}
                  </NavLink>
                )}
              </div>
            ))}
          </div>

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <MobileNav isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
    </nav>
  );
};

export default DashboardNav;