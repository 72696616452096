import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, FileText, Search, Calendar, CreditCard, Filter } from 'lucide-react';
import { useInvoices } from '../../context/InvoiceContext';
import { useClients } from '../../context/ClientContext';
import Loader from '../Loader';

const InvoicesList: React.FC = () => {
  const navigate = useNavigate();
  const { invoices, loading, error } = useInvoices();
  const { clients } = useClients();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600">Une erreur est survenue lors du chargement des factures</p>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'payee':
        return 'bg-green-100 text-green-800';
      case 'en_attente':
        return 'bg-yellow-100 text-yellow-800';
      case 'annulee':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: string) => {
    const labels: { [key: string]: string } = {
      'brouillon': 'Brouillon',
      'en_attente': 'En attente',
      'payee': 'Payée',
      'annulee': 'Annulée'
    };
    return labels[status] || status;
  };

  const calculateTotal = (invoice: any) => {
    return invoice.items.reduce((sum: number, item: any) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  const getClientName = (clientId: string) => {
    const client = clients.find(c => c.id === clientId);
    return client ? `${client.firstName} ${client.lastName}` : 'Client inconnu';
  };

  const getInvoicesByStatus = (status: string) => {
    return invoices.filter(invoice => invoice.status === status);
  };

  const filteredInvoices = invoices.filter(invoice => {
    const searchString = searchTerm.toLowerCase();
    const client = clients.find(c => c.id === invoice.clientId);
    const matchesSearch = invoice.number.toLowerCase().includes(searchString) ||
      (client && `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchString));
    const matchesStatus = statusFilter === 'all' || invoice.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  const statusSummary = [
    { status: 'brouillon', label: 'Brouillons', color: 'bg-gray-100' },
    { status: 'en_attente', label: 'En attente', color: 'bg-yellow-100' },
    { status: 'payee', label: 'Payées', color: 'bg-green-100' }
  ];

  return (
    <div className="bg-gradient-to-br from-violet-50 via-white to-violet-50 rounded-lg shadow-sm">
      {/* Status Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-8">
        {statusSummary.map(({ status, label, color }) => {
          const count = getInvoicesByStatus(status).length;
          return (
            <motion.div
              key={status}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className={`${color} p-4 rounded-lg shadow-sm`}
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-600">{label}</p>
                  <p className="text-2xl font-semibold text-gray-900">{count}</p>
                </div>
                <FileText className="h-8 w-8 text-gray-500 opacity-50" />
              </div>
            </motion.div>
          );
        })}
      </div>

      {/* Header avec recherche et bouton d'ajout */}
      <div className="p-8 border-b border-violet-100">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <div className="flex items-center space-x-4 w-full sm:w-auto">
            <div className="relative flex-1 max-w-md">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-violet-400" />
              </div>
              <input
                type="text"
                placeholder="Rechercher une facture..."
                className="pl-10 block w-full rounded-md border-violet-200 shadow-sm focus:ring-violet-500 focus:border-violet-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="relative">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="block w-full rounded-md border-violet-200 shadow-sm focus:ring-violet-500 focus:border-violet-500 pl-10"
              >
                <option value="all">Tous les statuts</option>
                <option value="brouillon">Brouillons</option>
                <option value="en_attente">En attente</option>
                <option value="payee">Payées</option>
                <option value="annulee">Annulées</option>
              </select>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Filter className="h-5 w-5 text-violet-400" />
              </div>
            </div>
          </div>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/dashboard/invoices/new')}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
          >
            <Plus className="h-5 w-5 mr-2" />
            Nouvelle facture
          </motion.button>
        </div>
      </div>

      {/* Liste des factures */}
      <div className="p-4">
        <div className="min-w-full rounded-lg overflow-hidden bg-white">
          <table className="min-w-full table-fixed divide-y divide-violet-100">
            <thead className="bg-violet-50">
              <tr>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-violet-500 uppercase tracking-wider">
                  Numéro
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-violet-500 uppercase tracking-wider">
                  Client
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-violet-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-violet-500 uppercase tracking-wider">
                  Échéance
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-violet-500 uppercase tracking-wider">
                  Montant
                </th>
                <th className="w-1/6 px-4 py-3 text-left text-xs font-medium text-violet-500 uppercase tracking-wider">
                  Statut
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-violet-50">
              <AnimatePresence>
                {filteredInvoices.map((invoice) => (
                  <motion.tr
                    key={invoice.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    whileHover={{ backgroundColor: 'rgba(139, 92, 246, 0.1)' }}
                    className="cursor-pointer"
                    onClick={() => navigate(`/dashboard/invoices/view/${invoice.id}`)}
                  >
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <FileText className="h-5 w-5 text-violet-400 mr-2" />
                        <span className="text-sm font-medium text-gray-900">
                          {invoice.number}
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <span className="text-sm text-gray-900">
                        {getClientName(invoice.clientId)}
                      </span>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm text-gray-900">
                        <Calendar className="h-4 w-4 text-violet-400 mr-1" />
                        {invoice.date}
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm text-gray-900">
                        <Calendar className="h-4 w-4 text-violet-400 mr-1" />
                        {invoice.dueDate}
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center text-sm font-medium text-gray-900">
                        <CreditCard className="h-4 w-4 text-violet-400 mr-1" />
                        {calculateTotal(invoice).toFixed(2)} €
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(invoice.status)}`}>
                        {getStatusLabel(invoice.status)}
                      </span>
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>

          {filteredInvoices.length === 0 && (
            <div className="text-center py-12">
              <FileText className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune facture</h3>
              <p className="mt-1 text-sm text-gray-500">
                Commencez par créer une nouvelle facture.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoicesList;