import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, Clock, MapPin, User, Building2, ChevronUp, ChevronDown, X } from 'lucide-react';
import { useCalendar } from '../context/CalendarContext';
import { useClients } from '../context/ClientContext';
import { calculateRoute } from '../utils/geocoding';

const NextAppointment = () => {
  const { events } = useCalendar();
  const { clients } = useClients();
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition | null>(null);
  const [routeInfo, setRouteInfo] = useState<{ duration: string; distance: string } | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Récupérer l'état de réduction depuis le localStorage
  useEffect(() => {
    const savedState = localStorage.getItem('nextAppointmentCollapsed');
    if (savedState !== null) {
      setIsCollapsed(JSON.parse(savedState));
    }
  }, []);

  // Sauvegarder l'état de réduction dans le localStorage
  const toggleCollapse = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem('nextAppointmentCollapsed', JSON.stringify(newState));
  };

  // Trouver le prochain rendez-vous
  const nextAppointment = events
    .filter(event => new Date(event.start) > new Date() && event.status !== 'cancelled')
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())[0];

  const client = nextAppointment ? clients.find(c => c.id === nextAppointment.clientId) : null;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setCurrentLocation(position),
        (error) => console.error('Error getting location:', error)
      );
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 64);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const calculateRouteInfo = async () => {
      if (!currentLocation || !client?.location) return;

      try {
        const info = await calculateRoute(
          {
            lat: currentLocation.coords.latitude,
            lng: currentLocation.coords.longitude
          },
          client.location
        );
        setRouteInfo(info);
      } catch (error) {
        console.error('Error calculating route:', error);
      }
    };

    calculateRouteInfo();
  }, [currentLocation, client]);

  if (!nextAppointment || !client || !isVisible) return null;

  const appointmentDate = new Date(nextAppointment.start);
  const isToday = new Date().toDateString() === appointmentDate.toDateString();
  const isTomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toDateString() === appointmentDate.toDateString();

  const getDateLabel = () => {
    if (isToday) return "Aujourd'hui";
    if (isTomorrow) return "Demain";
    return appointmentDate.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' });
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ 
          opacity: 1,
          y: hasScrolled ? 0 : 64,
          scale: isCollapsed ? 0.95 : 1
        }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
        className={`fixed right-4 z-[100] max-w-sm w-full bg-white rounded-lg shadow-lg border-2 ${
          isToday ? 'border-pink-500' : 'border-blue-500'
        } hover:shadow-xl transition-shadow`}
        style={{ top: hasScrolled ? '1rem' : '0' }}
      >
        <div className="absolute -left-3 top-1/2 transform -translate-y-1/2 space-y-2">
          <button
            onClick={toggleCollapse}
            className={`w-6 h-6 rounded-full ${
              isToday ? 'bg-pink-500 hover:bg-pink-600' : 'bg-blue-500 hover:bg-blue-600'
            } text-white flex items-center justify-center shadow-lg transition-all duration-200`}
            title={isCollapsed ? "Développer" : "Réduire"}
          >
            {isCollapsed ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
          </button>
        </div>

        <button
          onClick={() => setIsVisible(false)}
          className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-gray-500 hover:bg-gray-600 text-white flex items-center justify-center shadow-lg transition-colors"
          title="Fermer"
        >
          <X className="h-4 w-4" />
        </button>

        <motion.div
          initial={{ height: 'auto' }}
          animate={{ height: isCollapsed ? '48px' : 'auto' }}
          transition={{ duration: 0.2 }}
          className="overflow-hidden"
        >
          <div className="p-4">
            <div className="flex items-center justify-between mb-3">
              <motion.span
                initial={{ scale: 1 }}
                animate={{ scale: isToday ? [1, 1.05, 1] : 1 }}
                transition={{ 
                  duration: 0.5,
                  repeat: isToday ? Infinity : 0,
                  repeatDelay: 2
                }}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  isToday ? 'bg-pink-100 text-pink-800' : 'bg-blue-100 text-blue-800'
                }`}
              >
                Prochain rendez-vous
              </motion.span>
              <div className="flex items-center text-gray-500 text-sm">
                <Clock className="h-4 w-4 mr-1" />
                {appointmentDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
              </div>
            </div>

            {!isCollapsed && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-3"
              >
                <div className="flex items-start space-x-3">
                  <User className={`h-5 w-5 ${isToday ? 'text-pink-500' : 'text-blue-500'}`} />
                  <div>
                    <p className="font-medium text-gray-900">
                      {client.firstName} {client.lastName}
                    </p>
                    <div className="flex items-center text-sm text-gray-500">
                      <Building2 className="h-4 w-4 mr-1" />
                      {client.company}
                    </div>
                  </div>
                </div>

                <div className="flex items-center space-x-3 text-sm text-gray-600">
                  <Calendar className={`h-5 w-5 ${isToday ? 'text-pink-500' : 'text-blue-500'}`} />
                  <span>{getDateLabel()}</span>
                </div>

                {routeInfo && (
                  <div className="flex items-center space-x-3 text-sm text-gray-600">
                    <MapPin className={`h-5 w-5 ${isToday ? 'text-pink-500' : 'text-blue-500'}`} />
                    <span>{routeInfo.distance} • {routeInfo.duration}</span>
                  </div>
                )}
              </motion.div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NextAppointment;