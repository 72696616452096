import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Camera, X, Trash2, Plus, Upload, Image, AlertCircle } from 'lucide-react';
import { Photo } from '../context/ClientContext';
import { useStorage } from '../hooks/useStorage';

interface PhotoGalleryProps {
  photos: Photo[];
  onAddPhoto: (photo: Omit<Photo, 'id'>) => void;
  onDeletePhoto: (photoId: string, photoUrl: string) => void;
  clientId: string;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, onAddPhoto, onDeletePhoto, clientId }) => {
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
  const [isAddingPhoto, setIsAddingPhoto] = useState(false);
  const [newPhotoTitle, setNewPhotoTitle] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeletingPhoto, setIsDeletingPhoto] = useState<string | null>(null);
  
  const { upload, uploadProgress } = useStorage(`clients/${clientId}/photos`);

  const handleFileUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    
    if (!file.type.startsWith('image/')) {
      setUploadError('Le fichier doit être une image');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setUploadError('L\'image ne doit pas dépasser 5MB');
      return;
    }

    setIsUploading(true);
    setUploadError(null);

    try {
      const url = await upload(file);
      
      onAddPhoto({
        url,
        title: newPhotoTitle || file.name.split('.')[0],
        date: new Date().toISOString().split('T')[0]
      });

      setNewPhotoTitle('');
      setIsAddingPhoto(false);
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError('Erreur lors de l\'upload de l\'image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeletePhoto = async (photo: Photo) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette photo ?')) {
      setDeleteError(null);
      setIsDeletingPhoto(photo.id);
      
      try {
        await onDeletePhoto(photo.id, photo.url);
        if (selectedPhoto?.id === photo.id) {
          setSelectedPhoto(null);
        }
      } catch (error) {
        console.error('Error deleting photo:', error);
        setDeleteError('Erreur lors de la suppression de la photo. Veuillez réessayer.');
      } finally {
        setIsDeletingPhoto(null);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900 flex items-center">
          <Camera className="h-5 w-5 mr-2 text-blue-600" />
          Photos
        </h3>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsAddingPhoto(true)}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
        >
          <Plus className="h-4 w-4 mr-1" />
          Ajouter une photo
        </motion.button>
      </div>

      {deleteError && (
        <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md flex items-center">
          <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
          {deleteError}
        </div>
      )}

      {/* Photo grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {photos.map((photo) => (
          <motion.div
            key={photo.id}
            layoutId={`photo-${photo.id}`}
            className="relative group cursor-pointer aspect-square"
          >
            <img
              src={photo.url}
              alt={photo.title}
              className="w-full h-full object-cover rounded-lg transition-transform duration-200 group-hover:scale-105"
              onClick={() => setSelectedPhoto(photo)}
            />
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className={`absolute top-2 right-2 p-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-all duration-200 shadow-lg ${
                isDeletingPhoto === photo.id ? 'cursor-not-allowed opacity-50' : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleDeletePhoto(photo);
              }}
              disabled={isDeletingPhoto === photo.id}
            >
              <Trash2 className="h-4 w-4" />
            </motion.button>
          </motion.div>
        ))}
      </div>

      {/* Add photo modal */}
      <AnimatePresence>
        {isAddingPhoto && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[60] flex items-center justify-center p-4"
            onClick={() => setIsAddingPhoto(false)}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="bg-white rounded-lg shadow-xl max-w-md w-full p-6"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-900">Ajouter une photo</h3>
                <button
                  onClick={() => setIsAddingPhoto(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <div
                className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors ${
                  isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e.target.files)}
                />
                
                <div className="space-y-4">
                  {isUploading ? (
                    <div className="space-y-3">
                      <div className="flex justify-center">
                        <Upload className="h-12 w-12 text-blue-600 animate-bounce" />
                      </div>
                      <div className="text-sm text-gray-600">
                        Upload en cours... {Math.round(uploadProgress.progress)}%
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div 
                          className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                          style={{ width: `${uploadProgress.progress}%` }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex justify-center">
                        <Upload className="h-12 w-12 text-gray-400" />
                      </div>
                      <div className="text-sm text-gray-600">
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          className="text-blue-600 hover:text-blue-700 font-medium"
                        >
                          Cliquez pour uploader
                        </button>
                        {" "}ou glissez-déposez une image
                      </div>
                      <p className="text-xs text-gray-500">
                        PNG, JPG, GIF jusqu'à 5MB
                      </p>
                    </>
                  )}
                </div>
              </div>

              {uploadError && (
                <p className="mt-2 text-sm text-red-600 flex items-center">
                  <AlertCircle className="h-4 w-4 mr-2" />
                  {uploadError}
                </p>
              )}

              <div className="mt-4">
                <label htmlFor="photoTitle" className="block text-sm font-medium text-gray-700">
                  Titre de la photo
                </label>
                <input
                  type="text"
                  id="photoTitle"
                  value={newPhotoTitle}
                  onChange={(e) => setNewPhotoTitle(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Description de la photo..."
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Photo preview modal */}
      <AnimatePresence>
        {selectedPhoto && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-[60] flex items-center justify-center p-4"
            onClick={() => setSelectedPhoto(null)}
          >
            <motion.div
              layoutId={`photo-${selectedPhoto.id}`}
              className="relative max-w-4xl w-full max-h-[90vh]"
              onClick={e => e.stopPropagation()}
            >
              <img
                src={selectedPhoto.url}
                alt={selectedPhoto.title}
                className="w-full h-full object-contain rounded-lg"
              />
              <button
                onClick={() => setSelectedPhoto(null)}
                className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/70"
              >
                <X className="h-5 w-5" />
              </button>
              <div className="absolute bottom-4 left-4 right-4 bg-black/50 text-white p-4 rounded-lg">
                <h4 className="text-lg font-semibold">{selectedPhoto.title}</h4>
                <p className="text-sm text-gray-300">{selectedPhoto.date}</p>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={`absolute top-4 left-4 p-2 bg-red-600 text-white rounded-full hover:bg-red-700 ${
                  isDeletingPhoto === selectedPhoto.id ? 'cursor-not-allowed opacity-50' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeletePhoto(selectedPhoto);
                }}
                disabled={isDeletingPhoto === selectedPhoto.id}
              >
                <Trash2 className="h-4 w-4" />
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PhotoGallery;