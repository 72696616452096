import React, { useMemo } from 'react';
import { format, startOfMonth, endOfMonth, eachMonthOfInterval, subMonths } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { useInvoices } from '../../context/InvoiceContext';

const RevenueChart: React.FC = () => {
  const { invoices } = useInvoices();

  const data = useMemo(() => {
    // Obtenir les 6 derniers mois
    const endDate = new Date();
    const startDate = subMonths(endDate, 5);
    const months = eachMonthOfInterval({ start: startDate, end: endDate });

    // Calculer le chiffre d'affaires pour chaque mois (uniquement factures payées)
    return months.map(month => {
      const monthStart = startOfMonth(month);
      const monthEnd = endOfMonth(month);

      const monthlyRevenue = invoices
        .filter(invoice => {
          const invoiceDate = new Date(invoice.date);
          return (
            // Ne prendre en compte que les factures payées
            invoice.status === 'payee' &&
            // Dans le mois en cours
            invoiceDate >= monthStart && 
            invoiceDate <= monthEnd
          );
        })
        .reduce((total, invoice) => {
          return total + invoice.items.reduce((sum, item) => 
            sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
          );
        }, 0);

      return {
        month: format(month, 'MMM yyyy', { locale: fr }),
        revenue: monthlyRevenue
      };
    });
  }, [invoices]);

  const formatEuro = (value: number) => `${value.toLocaleString('fr-FR')} €`;

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <h3 className="text-lg font-semibold text-gray-900 mb-6">
        Évolution du chiffre d'affaires (factures payées)
      </h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="revenueGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#6B7280', fontSize: 12 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#6B7280', fontSize: 12 }}
              tickFormatter={formatEuro}
            />
            <Tooltip
              formatter={(value: number) => [formatEuro(value), 'Chiffre d\'affaires']}
              contentStyle={{
                backgroundColor: 'white',
                border: 'none',
                borderRadius: '0.5rem',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
              }}
            />
            <Area
              type="monotone"
              dataKey="revenue"
              stroke="#3B82F6"
              fillOpacity={1}
              fill="url(#revenueGradient)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RevenueChart;