import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  prompt: 'select_account'
});

const createOrUpdateUser = async (user: any, token?: string) => {
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    await setDoc(userRef, {
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      createdAt: new Date().toISOString(),
      lastLogin: new Date().toISOString(),
      accessToken: token
    });
  } else {
    await setDoc(userRef, {
      lastLogin: new Date().toISOString(),
      accessToken: token
    }, { merge: true });
  }
};

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    await createOrUpdateUser(result.user, credential?.accessToken);
    return result.user;
  } catch (error: any) {
    if (error.code === 'auth/popup-blocked') {
      throw new Error('Veuillez autoriser les popups pour ce site');
    } else if (error.code === 'auth/popup-closed-by-user') {
      throw new Error('Connexion annulée');
    } else if (error.code === 'auth/unauthorized-domain') {
      throw new Error('Ce domaine n\'est pas autorisé pour l\'authentification Google. Veuillez contacter l\'administrateur.');
    } else {
      console.error('Google Sign In Error:', error);
      throw new Error('Erreur lors de la connexion avec Google');
    }
  }
};