import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FileCheck, 
  User, 
  Building2, 
  Calendar, 
  CreditCard, 
  Check, 
  Clock, 
  Ban,
  X,
  Mail,
  Phone,
  Tag,
  Edit,
  Trash2,
  Save,
  StickyNote,
  FileText,
  Plus,
  MapPin
} from 'lucide-react';
import { useClients } from '../context/ClientContext';
import { useInvoices } from '../context/InvoiceContext';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TodoList from './TodoList';
import PhotoGallery from './PhotoGallery';
import LocationTracker from './LocationTracker';
import { storage } from '../config/firebase';
import { ref, deleteObject } from 'firebase/storage';

interface ClientDetailsProps {
  clientId: string;
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ clientId }) => {
  const navigate = useNavigate();
  const { clients, deleteClient, updateClient } = useClients();
  const { invoices } = useInvoices();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState('');
  const client = clients.find(c => c.id === clientId);

  if (!client) {
    navigate('/dashboard/clients');
    return null;
  }

  React.useEffect(() => {
    setNotes(client.notes || '');
  }, [client.notes]);

  const handleEdit = () => {
    navigate(`/dashboard/clients/edit/${clientId}`);
  };

  const handleDelete = () => {
    deleteClient(client.id);
    navigate('/dashboard/clients');
  };

  const handleSaveNotes = () => {
    updateClient(client.id, { notes });
    setIsEditingNotes(false);
  };

  const handleNewInvoice = () => {
    navigate('/dashboard/invoices/new', { state: { clientId: client.id } });
  };

  const handleViewInvoice = (invoiceId: number) => {
    navigate(`/dashboard/invoices/view/${invoiceId}`);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'paid':
        return 'bg-green-100 text-green-800';
      case 'overdue':
        return 'bg-red-100 text-red-800';
      case 'sent':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: string) => {
    const labels: { [key: string]: string } = {
      'draft': 'Brouillon',
      'sent': 'Envoyée',
      'paid': 'Payée',
      'overdue': 'En retard'
    };
    return labels[status] || status;
  };

  const clientInvoices = invoices.filter(invoice => invoice.clientId === client.id);

  const calculateInvoiceTotal = (invoice: any) => {
    return invoice.items.reduce((sum: number, item: any) => 
      sum + (item.quantity * item.unitPrice * (1 + item.tax / 100)), 0
    );
  };

  const handleDeletePhoto = async (photoId: string, photoUrl: string) => {
    try {
      // Delete from Firebase Storage
      const storageRef = ref(storage, photoUrl);
      await deleteObject(storageRef);

      // Update client document in Firestore
      const updatedPhotos = client.photos.filter(p => p.id !== photoId);
      await updateClient(client.id, { photos: updatedPhotos });
    } catch (error) {
      console.error('Error deleting photo:', error);
      throw error;
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={() => navigate('/dashboard/clients')}
    >
      <motion.div 
        className="bg-white rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto"
        onClick={e => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
      >
        {/* En-tête */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                <User className="h-6 w-6 mr-2 text-blue-600" />
                {client.firstName} {client.lastName}
              </h2>
              <p className="mt-1 text-gray-500 flex items-center">
                <Building2 className="h-4 w-4 mr-1" />
                {client.company}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleEdit}
                className="p-2 text-blue-600 hover:text-blue-700 rounded-full hover:bg-blue-50 transition-colors"
              >
                <Edit className="h-5 w-5" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsDeleteModalOpen(true)}
                className="p-2 text-red-600 hover:text-red-700 rounded-full hover:bg-red-50 transition-colors"
              >
                <Trash2 className="h-5 w-5" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/dashboard/clients')}
                className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100 transition-colors"
              >
                <X className="h-5 w-5" />
              </motion.button>
            </div>
          </div>
        </div>

        {/* Contenu */}
        <div className="p-6 space-y-8">
          {/* Contact */}
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Contact</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 }}
                className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg"
              >
                <Mail className="h-5 w-5 text-blue-600" />
                <div>
                  <p className="text-sm text-gray-500">Email</p>
                  <p className="text-gray-900">{client.email}</p>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg"
              >
                <Phone className="h-5 w-5 text-blue-600" />
                <div>
                  <p className="text-sm text-gray-500">Téléphone</p>
                  <p className="text-gray-900">{client.phone}</p>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                className="col-span-2 flex items-start space-x-3 p-3 bg-gray-50 rounded-lg"
              >
                <MapPin className="h-5 w-5 text-blue-600 mt-1" />
                <div>
                  <p className="text-sm text-gray-500">Adresse</p>
                  <p className="text-gray-900">{client.address}</p>
                </div>
              </motion.div>
            </div>
          </div>

          {/* Localisation */}
          <LocationTracker
            clientLocation={client.location}
            clientAddress={client.address}
            onUpdateLocation={(location) => {
              updateClient(client.id, { location });
            }}
          />

          {/* Photos */}
          <PhotoGallery
            photos={client.photos || []}
            onAddPhoto={async (photo) => {
              const newPhoto = {
                ...photo,
                id: Date.now().toString()
              };
              await updateClient(client.id, {
                photos: [...(client.photos || []), newPhoto]
              });
            }}
            onDeletePhoto={handleDeletePhoto}
            clientId={client.id}
          />

          {/* Tâches */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
          >
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900">Tâches</h3>
            </div>
            <TodoList
              todos={client.todos}
              onAdd={(text) => {
                const newTodo = {
                  id: Date.now(),
                  text,
                  completed: false,
                  createdAt: new Date().toLocaleDateString()
                };
                updateClient(client.id, {
                  todos: [...client.todos, newTodo]
                });
              }}
              onToggle={(todoId) => {
                const updatedTodos = client.todos.map(todo =>
                  todo.id === todoId ? { ...todo, completed: !todo.completed } : todo
                );
                updateClient(client.id, { todos: updatedTodos });
              }}
              onDelete={(todoId) => {
                const updatedTodos = client.todos.filter(todo => todo.id !== todoId);
                updateClient(client.id, { todos: updatedTodos });
              }}
            />
          </motion.div>

          {/* Notes */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900">Notes</h3>
              {!isEditingNotes ? (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsEditingNotes(true)}
                  className="text-blue-600 hover:text-blue-700 p-2 rounded-full hover:bg-blue-50"
                >
                  <Edit className="h-4 w-4" />
                </motion.button>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleSaveNotes}
                  className="text-green-600 hover:text-green-700 p-2 rounded-full hover:bg-green-50"
                >
                  <Save className="h-4 w-4" />
                </motion.button>
              )}
            </div>
            
            {isEditingNotes ? (
              <motion.textarea
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full h-32 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Ajoutez vos notes ici..."
                autoFocus
              />
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="p-3 bg-gray-50 rounded-lg min-h-[8rem]"
              >
                {notes ? (
                  <p className="text-gray-700 whitespace-pre-wrap">{notes}</p>
                ) : (
                  <p className="text-gray-400 italic">Aucune note</p>
                )}
              </motion.div>
            )}
          </motion.div>

          {/* Factures */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.9 }}
          >
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900">Factures</h3>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleNewInvoice}
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                <Plus className="h-4 w-4 mr-1" />
                Nouvelle facture
              </motion.button>
            </div>

            {clientInvoices.length > 0 ? (
              <div className="overflow-x-auto bg-gray-50 rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Numéro
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Échéance
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Montant
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Statut
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {clientInvoices.map((invoice) => (
                      <motion.tr
                        key={invoice.id}
                        whileHover={{ backgroundColor: 'rgba(249, 250, 251, 0.5)' }}
                        className="cursor-pointer"
                        onClick={() => handleViewInvoice(invoice.id)}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FileText className="h-5 w-5 text-gray-400 mr-2" />
                            <span className="text-sm font-medium text-gray-900">
                              {invoice.number}
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center text-sm text-gray-900">
                            <Calendar className="h-4 w-4 mr-1 text-gray-400" />
                            {invoice.date}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center text-sm text-gray-900">
                            <Calendar className="h-4 w-4 mr-1 text-gray-400" />
                            {invoice.dueDate}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center text-sm font-medium text-gray-900">
                            <CreditCard className="h-4 w-4 mr-1 text-gray-400" />
                            {calculateInvoiceTotal(invoice).toFixed(2)} €
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(invoice.status)}`}>
                            {getStatusLabel(invoice.status)}
                          </span>
                        </td>
                      </motion.tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-12 bg-gray-50 rounded-lg">
                <FileText className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune facture</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Commencez par créer une nouvelle facture pour ce client.
                </p>
              </div>
            )}
          </motion.div>
        </div>

        {/* Footer */}
        <div className="p-6 border-t border-gray-200">
          <div className="flex justify-between">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setIsDeleteModalOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Supprimer le client
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleEdit}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Edit className="h-4 w-4 mr-2" />
              Modifier le client
            </motion.button>
          </div>
        </div>
      </motion.div>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        clientName={`${client.firstName} ${client.lastName}`}
      />
    </div>
  );
};

export default ClientDetails;