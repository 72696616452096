import React, { useState } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer, View } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import fr from 'date-fns/locale/fr';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { useCalendar } from '../../context/CalendarContext';
import AddEventModal from './AddEventModal';
import EventDetailsModal from './EventDetailsModal';
import { CalendarEvent } from '../../context/CalendarContext';
import { useActivity } from '../../context/ActivityContext';

const locales = {
  'fr': fr,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: fr }),
  getDay,
  locales,
});

// Version avec drag and drop
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

// Messages en français
const messages = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  work_week: 'Semaine de travail',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Événement',
  noEventsInRange: 'Aucun événement sur cette période',
  showMore: (total: number) => `+ ${total} événement(s)`,
};

const Calendar: React.FC = () => {
  const { events, updateEvent } = useCalendar();
  const { addActivity } = useActivity();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<{ start: Date; end: Date } | null>(null);

  // Convertir les dates string en objets Date
  const calendarEvents = events.map(event => ({
    ...event,
    start: new Date(event.start),
    end: new Date(event.end)
  }));

  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {
    setSelectedSlot({ start, end });
    setIsAddModalOpen(true);
  };

  const handleSelectEvent = (event: CalendarEvent) => {
    setSelectedEvent(event);
  };

  const handleEventDrop = async ({ event, start, end }: any) => {
    try {
      await updateEvent(event.id, {
        start: start.toISOString(),
        end: end.toISOString()
      });
      addActivity('calendar', 'update', 'Rendez-vous déplacé', event.id);
    } catch (error) {
      console.error('Erreur lors du déplacement du rendez-vous:', error);
    }
  };

  const handleEventResize = async ({ event, start, end }: any) => {
    try {
      await updateEvent(event.id, {
        start: start.toISOString(),
        end: end.toISOString()
      });
      addActivity('calendar', 'update', 'Durée du rendez-vous modifiée', event.id);
    } catch (error) {
      console.error('Erreur lors de la modification de la durée:', error);
    }
  };

  const eventStyleGetter = (event: CalendarEvent) => {
    let style = {
      backgroundColor: '#3B82F6', // Bleu par défaut
      borderRadius: '4px',
      opacity: 1,
      color: '#fff',
      border: 'none',
      display: 'block'
    };

    // Couleurs par type d'intervention
    switch (event.type) {
      case 'maintenance':
        style.backgroundColor = '#10B981'; // Vert
        break;
      case 'installation':
        style.backgroundColor = '#6366F1'; // Indigo
        break;
      case 'depannage':
        style.backgroundColor = '#EF4444'; // Rouge
        break;
    }

    // Si annulé, griser
    if (event.status === 'cancelled') {
      style.backgroundColor = '#6B7280';
      style.opacity = 0.7;
    }

    return { style };
  };

  return (
    <div className="h-[700px] bg-white rounded-lg shadow-sm p-4">
      <DragAndDropCalendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 'calc(100% - 2rem)' }}
        selectable
        resizable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        eventPropGetter={eventStyleGetter}
        views={['month', 'week', 'day']}
        defaultView="week"
        messages={messages}
        formats={{
          timeGutterFormat: 'HH:mm',
          eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) => 
            `${format(start, 'HH:mm', { locale: fr })} - ${format(end, 'HH:mm', { locale: fr })}`,
          dayRangeHeaderFormat: ({ start, end }: { start: Date; end: Date }) =>
            `${format(start, 'dd MMMM', { locale: fr })} - ${format(end, 'dd MMMM', { locale: fr })}`,
        }}
        popup
        step={30}
        timeslots={2}
      />

      <AddEventModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setSelectedSlot(null);
        }}
        selectedSlot={selectedSlot}
      />

      <EventDetailsModal
        event={selectedEvent}
        onClose={() => setSelectedEvent(null)}
      />
    </div>
  );
};

export default Calendar;