import React, { createContext, useContext } from 'react';
import { enableIndexedDbPersistence, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';
import { db } from '../config/firebase';
import useFirestore from '../hooks/useFirestore';
import { useAuth } from './AuthContext';

// Enable persistence with unlimited cache size
try {
  enableIndexedDbPersistence(db, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  }).catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time
      console.log('Persistence unavailable - multiple tabs open');
    } else if (err.code === 'unimplemented') {
      // The current browser doesn't support persistence
      console.log('Persistence unavailable - browser unsupported');
    }
  });
} catch (err) {
  console.log('Error enabling persistence:', err);
}

export interface Product {
  id: string;
  userId: string;
  name: string;
  description: string;
  category: 'climatiseur' | 'pompe-chaleur' | 'ventilation' | 'accessoire';
  reference: string;
  buyingPrice: number;
  sellingPrice: number;
  margin: number;
  stock: number;
  createdAt: string;
  updatedAt: string;
}

interface ProductContextType {
  products: Product[];
  loading: boolean;
  error: Error | null;
  addProduct: (product: Omit<Product, 'id' | 'userId' | 'margin' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateProduct: (id: string, product: Partial<Product>) => Promise<void>;
  deleteProduct: (id: string) => Promise<void>;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const ProductProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const { documents: products, loading, error, add, update, remove } = useFirestore<Product>({
    collection: 'products',
    queries: [{ field: 'userId', operator: '==', value: user?.uid }],
    orderByField: 'createdAt',
    orderDirection: 'desc'
  });

  const calculateMargin = (buyingPrice: number, sellingPrice: number): number => {
    return sellingPrice - buyingPrice;
  };

  const addProduct = async (productData: Omit<Product, 'id' | 'userId' | 'margin' | 'createdAt' | 'updatedAt'>) => {
    if (!user) throw new Error('User must be authenticated');

    const now = new Date().toISOString();
    const margin = calculateMargin(productData.buyingPrice, productData.sellingPrice);
    
    const newProduct = {
      ...productData,
      userId: user.uid,
      margin,
      createdAt: now,
      updatedAt: now
    };

    return add(newProduct);
  };

  const updateProduct = async (id: string, updates: Partial<Product>) => {
    const updatedData = {
      ...updates,
      updatedAt: new Date().toISOString()
    };

    // Recalculer la marge si le prix d'achat ou de vente est modifié
    if ('buyingPrice' in updates || 'sellingPrice' in updates) {
      const product = products.find(p => p.id === id);
      if (product) {
        const buyingPrice = updates.buyingPrice ?? product.buyingPrice;
        const sellingPrice = updates.sellingPrice ?? product.sellingPrice;
        updatedData.margin = calculateMargin(buyingPrice, sellingPrice);
      }
    }

    await update(id, updatedData);
  };

  const deleteProduct = async (id: string) => {
    await remove(id);
  };

  return (
    <ProductContext.Provider value={{
      products,
      loading,
      error,
      addProduct,
      updateProduct,
      deleteProduct
    }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProducts = () => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductProvider');
  }
  return context;
};