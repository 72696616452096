import React from 'react';
import { motion } from 'framer-motion';
import { Package } from 'lucide-react';
import ProductsList from '../../components/products/ProductsList';

const ProductsManager: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 via-white to-teal-50">
      {/* Header */}
      <div className="mb-8 relative p-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative z-10"
        >
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-600 to-teal-400">
            Gestion des Produits
          </h1>
          <p className="mt-2 text-gray-600">
            Gérez votre catalogue de produits et suivez vos marges
          </p>
        </motion.div>
      </div>

      <div className="container mx-auto px-4 py-6">
        <ProductsList />
      </div>
    </div>
  );
};

export default ProductsManager;