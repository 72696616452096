import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { AuthProvider } from './context/AuthContext';
import { ClientProvider } from './context/ClientContext';
import { InvoiceProvider } from './context/InvoiceContext';
import { QuoteProvider } from './context/QuoteContext';
import { TaskProvider } from './context/TaskContext';
import { ActivityProvider } from './context/ActivityContext';
import { CalendarProvider } from './context/CalendarContext';
import { ProductProvider } from './context/ProductContext';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import PrivateRoute from './components/PrivateRoute';
import NextAppointment from './components/NextAppointment';

function AppRoutes() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />
      <AnimatePresence mode="wait">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <ActivityProvider>
                    <ClientProvider>
                      <InvoiceProvider>
                        <QuoteProvider>
                          <TaskProvider>
                            <CalendarProvider>
                              <ProductProvider>
                                <>
                                  <NextAppointment />
                                  <Dashboard />
                                </>
                              </ProductProvider>
                            </CalendarProvider>
                          </TaskProvider>
                        </QuoteProvider>
                      </InvoiceProvider>
                    </ClientProvider>
                  </ActivityProvider>
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;