import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, updateProfile as updateFirebaseProfile } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import { signInWithGoogle } from '../utils/firebaseAuth';

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<User>;
  loginWithGoogle: () => Promise<User>;
  register: (email: string, password: string, displayName: string) => Promise<User>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  updateProfile: (updates: { displayName?: string; photoURL?: string }) => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { 
    user, 
    login: firebaseLogin, 
    register: firebaseRegister,
    logout: firebaseLogout,
    loading 
  } = useFirebaseAuth();

  const login = async (email: string, password: string) => {
    try {
      const user = await firebaseLogin(email, password);
      
      // Mettre à jour le timestamp de dernière connexion
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        lastLogin: new Date().toISOString()
      }, { merge: true });

      navigate('/dashboard');
      return user;
    } catch (error) {
      throw new Error('Identifiants invalides');
    }
  };

  const loginWithGoogle = async () => {
    try {
      const user = await signInWithGoogle();
      navigate('/dashboard');
      return user;
    } catch (error) {
      throw error;
    }
  };

  const register = async (email: string, password: string, displayName: string) => {
    try {
      const user = await firebaseRegister(email, password);
      
      // Mettre à jour le profil Firebase
      await updateFirebaseProfile(user, { displayName });
      
      // Créer le document utilisateur dans Firestore
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        email,
        displayName,
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString()
      });

      navigate('/dashboard');
      return user;
    } catch (error) {
      throw new Error('Erreur lors de l\'inscription');
    }
  };

  const logout = async () => {
    try {
      await firebaseLogout();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      throw error;
    }
  };

  const updateProfile = async (updates: { displayName?: string; photoURL?: string }) => {
    if (!user) throw new Error('Utilisateur non connecté');

    try {
      // Mettre à jour le profil Firebase
      await updateFirebaseProfile(user, updates);
      
      // Mettre à jour le document utilisateur dans Firestore
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      }, { merge: true });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      throw new Error('Erreur lors de la mise à jour du profil');
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      login,
      loginWithGoogle,
      register,
      logout,
      isAuthenticated: !!user,
      updateProfile,
      loading
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth doit être utilisé dans un AuthProvider');
  }
  return context;
};